/* eslint eqeqeq: 0 */
import fetch from 'isomorphic-fetch';
// eslint-disable-next-line
import { polyfill } from 'es6-promise';
import JwtDecode from "jwt-decode";
import numeral from 'numeral';
import moment from 'moment';

export const authKey = 'fastcash@admintoken';
export const settingKey = 'fastcash@adminstumps';
export const lenderKey = 'fastcash@editlender';

const debug = process.env.REACT_APP_DEBUG;
// console.log(debug);
export const liveStatus = process.env.REACT_APP_LIVE == 'true';
export const rootURL = debug == 'true' ? process.env.REACT_APP_DEBUG_API : process.env.REACT_APP_LIVE_API;
export const dateLimit = debug == 'true' ? process.env.REACT_APP_DEBUG_DATE_LIMIT : process.env.REACT_APP_DATE_LIMIT;
export const ENC_Key = debug == 'true' ? process.env.REACT_APP_DEBUG_AES_128_ENCRYPT_KEY : process.env.REACT_APP_AES_128_ENCRYPT_KEY;
export const ENC_Vector = debug == 'true' ? process.env.REACT_APP_DEBUG_AES_128_ENCRYPT_IV : process.env.REACT_APP_AES_128_ENCRYPT_IV;

export const paymentMerchantId = debug == 'true' ? process.env.REACT_APP_DEBUG_PAYMENT_MERCHANT_ID : process.env.REACT_APP_PAYMENT_MERCHANT_ID;
export const paymentApiKey = debug == 'true' ? process.env.REACT_APP_DEBUG_REMITA_PAYMENT_API_KEY : process.env.REACT_APP_REMITA_PAYMENT_API_KEY;
export const paymentApiToken = debug == 'true' ? process.env.REACT_APP_DEBUG_REMITA_PAYMENT_API_TOKEN : process.env.REACT_APP_REMITA_PAYMENT_API_TOKEN;

export const superLenderID = parseInt(process.env.REACT_APP_SUPER_LENDER_ID, 10);

export const merchantId = process.env.REACT_APP_REMITA_MERCHANT_ID;
export const apiKey = process.env.REACT_APP_REMITA_API_KEY;
export const apiToken = process.env.REACT_APP_REMITA_API_TOKEN;

export const bankCode = debug == 'true' ? process.env.REACT_APP_DEBUG_FROM_BANK_CODE : process.env.REACT_APP_FROM_BANK_CODE;
export const bankAccount = debug == 'true' ? process.env.REACT_APP_DEBUG_DEBIT_ACCOUNT : process.env.REACT_APP_DEBIT_ACCOUNT;

export const baseURL = `${rootURL}/v1`;

export const settingsAPI = '/settings';
export const verifyAPI = '/auth/verify';
export const signupAPI = '/auth/signup';
export const loginAPI = '/auth/login';
export const adminFindUserAPI = '/admin/find-user';
export const loanAPI = '/loans';
export const userAPI = '/users';
export const adminLoginAPI = '/auth/signin';
export const officeAPI = '/offices';
export const merchantAPI = '/merchants';
export const uploadPayslipAPI = '/upload/payslip';
export const uploadScheduleAPI = '/upload/schedule';
export const scheduleAPI = '/schedules';
export const reportAPI = '/report';
export const walletAPI = '/wallet';
export const updateUSSDAPI = '/ussd/service/setting';
export const remitaLoanHistoryAPI = '/loan-history';
export const searchAPI = '/search';
export const transactionAPI = '/transactions';
export const forgotPasswordAPI = '/auth/forgot-password';
export const resetPasswordAPI = '/auth/admin/reset-password';
export const supportAPI = '/supports';
export const notificationAPI = '/notifications';
export const supportMailAPI = '/support-mails';
export const uploadDocAPI = '/upload/attachment';
export const lenderAPI = '/lenders';
export const uploadUsersAPI = '/upload/users';
export const multiFundsAPI = '/multi-funds';
export const remitaTransactionAPI = '/remita-transactions';
export const withdrawAPI = '/withdrawals';

export const getUserID = () => {
	const date = new Date();
	const token = localStorage.getItem(authKey);
	if (token) {
		const decoded = JwtDecode(token);
		const userid = decoded.sub;
		if (decoded.exp < date.getTime()) {
			return userid;
		}
	}
	return null;
};

const defaultHeaders = {
	Accept: 'application/json',
	'Content-Type': 'application/json',
};

const headers = (auth = false) => {
	if (auth) {
		const jwt = `Bearer ${localStorage.getItem(authKey)}`;
		return { ...defaultHeaders, Authorization: jwt };
	}

	return { ...defaultHeaders };
};

export const checkStatus = async response => {
	if (response.ok) {
		return response;
	} else {
		if (response.statusText == 'Unauthorized') {
			localStorage.removeItem(authKey);
			localStorage.removeItem(settingKey);
			window.location.reload(true);
			return;
		}

		const message = await response.text();
		const err = JSON.parse(message);
		throw Object.freeze({ message: err.error });
	}
};

const parseJSON = response => {
	return response.json();
};

export const httpRequest = (url, method, auth, data) => {
	const body = JSON.stringify(data);
	return fetch(url, {
		method: method,
		headers: headers(auth),
		body: body,
	})
		.then(checkStatus)
		.then(parseJSON);
};

export const httpRequest2 = (url, method, body) => {
	const jwt = `Bearer ${localStorage.getItem(authKey)}`;
	const headers = {
		Accept: 'application/json, */*',
		Authorization: jwt,
	};
	return fetch(url, {
		method: method,
		headers: headers,
		body: body,
	})
		.then(checkStatus)
		.then(parseJSON);
};

export const getTitle = (status, info = '') => {
	let message = '';

	switch (status) {
		case 'red':
			message = info === '' ? 'declined' : `declined: ${info}`;
			break;
		case 'green':
			message = 'approved';
			break;
		case 'yellow':
			message = info === '' ? 'pending' : info;
			break;
		case 'dark':
			message = info === '' ? 'pending' : info;
			break;
		default:
			message = '';
			break;
	}
	
	return message;
};

export const getPTitle = status => {
	let message = '';
	switch (status) {
		case 'green':
			message = 'Completed';
			break;
		case 'yellow':
			message = 'Pending';
			break;
		default:
			message = '';
			break;
	}
	return message;
};

export const currency = amount => `₦${numeral(amount).format('0,0.00')}`;

export const formatDate = (date, format) => {
	if(!date) { return '-'; }
	if(format && format === '') { return date; }
	return moment(date, format).format('D.MMM.YYYY');
};

export const paginate = (array, page_size, page_number) => {
	return array.slice((page_number - 1) * page_size, page_number * page_size);
};

export const months = [
	{id: 'January', name: 'January'},
	{id: 'February', name: 'February'},
	{id: 'March', name: 'March'},
	{id: 'April', name: 'April'},
	{id: 'May', name: 'May'},
	{id: 'June', name: 'June'},
	{id: 'July', name: 'July'},
	{id: 'August', name: 'August'},
	{id: 'September', name: 'September'},
	{id: 'October', name: 'October'},
	{id: 'November', name: 'November'},
	{id: 'December', name: 'December'},
];

export const rptHeader = [
	{name: 'repayment', list: ['S/N', 'Name', 'Core Bank ID', 'IPPIS', 'Phone', 'Organisation', 'Mandate Ref', 'Loan ID', 'Lender', 'Loan Amt', 'Disbursed Amt', 'Loan Type', 'Tenure', 'Monthly Principal', 'Monthly Interest', 'Disbursed Date', 'Repayment Source', 'Repayment Date', 'Repayment Amount', 'Repayment Principal', 'Repayment Interest', 'Repayment Reference', 'Status']},
	{name: 'loan', list: ['S/N', 'Name', 'Core Bank ID', 'IPPIS', 'Phone', 'Organisation', 'Mandate Ref', 'Loan ID', 'Lender', 'Loan Amt', 'Disbursed Amt', 'Loan Type', 'Tenure', 'Monthly Principal', 'Monthly Interest', 'Request Date', 'Aproved Date', 'Disbursed Date', 'Status']},
	{name: 'disbursed-loan', list: ['S/N', 'Name', 'Core Bank ID', 'IPPIS', 'Phone', 'Organisation', 'Mandate Ref', 'Loan ID', 'Lender', 'Loan Amt', 'Disbursed Amt', 'Loan Type', 'Tenure', 'Monthly Principal', 'Monthly Interest', 'Request Date', 'Aproved Date', 'Disbursed Date', 'Start Date', 'End Date', 'Platform', 'Status']},
	{name: 'wallet', list: ['S/N', 'Name', 'Core Bank ID', 'IPPIS', 'Phone', 'Organisation', 'Mandate Ref', 'Loan ID', 'Lender', 'Loan Amt', 'Disburse Amt', 'Loan Type', 'Tenure', 'Monthly Principal', 'Monthly Interest', 'Disburse Date', 'Repayment Source', 'Repaymment Date', 'Amount', 'Repayment Principal', 'Repayment Interest', 'Repayment Reference', 'Status', 'Transaction Date', 'Type', 'Narration']},
	{name: 'credit-wallet', list: ['S/N', 'Name', 'Core Bank ID', 'IPPIS', 'Phone', 'Organisation', 'Mandate Ref', 'Loan ID', 'Lender', 'Loan Amt', 'Disbursed Amt', 'Loan Type', 'Tenure', 'Monthly Principal', 'Monthly Interest', 'Disbursed Date', 'Repayment Source', 'Repayment Date', 'Repayment Amount', 'Repayment Principal', 'Repayment Interest', 'Repayment Reference', 'Status']},
];

export const updateImmutable = (items, data) => {
	const item = items.find((i) => i.id === data.id);
	if (item) {
		const index = items.findIndex((i) => i.id === data.id);
		return [
			...items.slice(0, index),
			{ ...item, ...data },
			...items.slice(index + 1),
		];
	}

	return items;
};
