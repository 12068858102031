import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'antd/dist/antd.css';
import 'font-awesome/css/font-awesome.css';
import 'simple-line-icons/css/simple-line-icons.css';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import 'react-block-ui/style.css';

import './assets/css/dropzone.css';
import './assets/css/main.css';
import './assets/css/pages.css';
import './assets/css/authenticate.css';
import './assets/css/search.css';
import './assets/css/custom.css';
import './assets/css/theme.css';

import App from './App';
import configureStore from './store';
import { getUserID, httpRequest, baseURL, userAPI, settingKey, authKey, lenderKey } from './config/constants';
import { changeUserData, stopPreloading, setUnapprovedTransactions, setUnliquidatedLoans, setWithdrawals, setLoanCancelRequests, setWalletDebitRequests } from './actions/user';
import { setLoanAnalytics } from './actions/loan';
import { loadBanks, setSlips, loadRoles } from './actions/general';
import { loadNotifications } from './actions/notification';
import { loadMail } from './actions/mail';
import { CHANGE_USER_DATA } from './actions/types';
import { loadLenders, setLenderProfile } from './actions/lender';
import { putSettings } from './actions/settings';
import { setUnreadSupport } from './actions/support';
import history from './history';

const store = configureStore();
const userID = getUserID();

const checkResetToken = async () => {
	const location = history.location.pathname.split('/');
	if (location.length > 1 && location[1] === 'reset-password') {
		try {
			const rs = await httpRequest(`${baseURL}/auth/check-token/${location[2]}`, 'GET', false);
			if (rs.reset) {
			} else {
				store.dispatch(stopPreloading());
				history.push('/page-not-found');
			}
		} catch (e) {
			store.dispatch(stopPreloading());
			history.push('/page-not-found');
		}
	}
};

checkResetToken();

if (userID) {
	httpRequest(`${baseURL}${userAPI}/${userID}?platform=admin`, 'GET', true)
		.then(response => {
			if (response.user.role === 'admin' || response.user.role === 'super') {
				store.dispatch(changeUserData(response.user, true));
				store.dispatch(setLoanAnalytics(response.loan_details));
				store.dispatch(loadMail(response.mails));
				store.dispatch(setSlips(response.slips));
				store.dispatch(loadNotifications(response.user));
				store.dispatch(loadBanks(response.banks));
				store.dispatch(loadLenders(response.lenders));
				store.dispatch(loadRoles(response.roles));
				store.dispatch(putSettings(response.settings));
				store.dispatch(setUnreadSupport(response.unread_support));
				store.dispatch(setUnapprovedTransactions(response.unapproved_transactions));
				store.dispatch(setWithdrawals(response.withdrawals));
				store.dispatch(setLoanCancelRequests(response.cancel_requests));	
				store.dispatch(setWalletDebitRequests(response.wallet_debit_requests));								
				store.dispatch(setLenderProfile(response.lender));
				store.dispatch(setUnliquidatedLoans(response.unliquidated_loans));
				document.body.className = 'pages';
				if(history.location.pathname === '/') {
					history.push('/dashboard');
				}
			} else {
				localStorage.removeItem(authKey);
				localStorage.removeItem(settingKey);
				localStorage.removeItem(lenderKey);
				store.dispatch({ type: CHANGE_USER_DATA, user: null, status: false });
				store.dispatch(stopPreloading());
				history.push('/?not-authenticated');
			}
		})
		.catch(error => {
			localStorage.removeItem(authKey);
			localStorage.removeItem(settingKey);
			localStorage.removeItem(lenderKey);
			store.dispatch({ type: CHANGE_USER_DATA, user: null, status: false });
			store.dispatch(stopPreloading());
			history.push('/?not-authenticated');
		});
} else {
	const location = history.location.pathname.split('/');
	store.dispatch(stopPreloading());
	if (location.length > 1 && (location[1] !== 'forgot-password' && location[1] !== 'reset-password')) {
		history.push('/?not-authenticated');
	}
}

ReactDOM.render(
	<Provider store={store}>
		<Router history={history}>
			<App />
		</Router>
	</Provider>,
	document.getElementById('main'),
);
