/* eslint eqeqeq: 0 */
import * as types from '../actions/types';

const INITIAL_STATE = {
	user: null,
	loggedIn: false,
	preloading: true,
	show_menu: true,
	user_id: '',
	show_profile: false,
	customers: [],
	payslip: null,
	unapproved_transactions: 0,
	unliquidated_loans: 0,
	withdrawals: 0,
	cancel_requests: 0,
	customer: null,
	remitaDatum: null,
	wallet_debit_requests: 0,
	ussd_settings: {
		limit: 0,
		message: ""
	}
};

const user = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.START_PRELOADING:
			return { ...state, preloading: true };
		case types.STOP_PRELOADING:
			return { ...state, preloading: false };
		case types.CHANGE_USER_DATA:
			return { ...state, user: action.user, loggedIn: action.status };
		case types.SIGN_OUT:
			return { ...state, ...INITIAL_STATE, preloading: false };
		case types.TOGGLE_MENU:
			return { ...state, show_menu: action.payload };
		case types.SHOW_PROFILE:
			return { ...state, show_profile: action.data.show_profile, user_id: action.data.user_id };
		case types.CLOSE_PROFILE:
			return { ...state, show_profile: false, user_id: '' };
		// customers
		case types.LOAD_CUSTOMERS:
			return { ...state, customers: [...action.payload] };
		case types.SET_USER_PAYSLIP:
			return { ...state, payslip: action.payload };
		case types.SET_REMITA_PAYSLIP:
			return { ...state, remitaDatum: action.payload };
		case types.SET_EDIT_CUSTOMER:
			return { ...state, customer: action.payload };
		// notifications
		case types.SET_UNAPPROVED_TRANSACTIONS:
			return { ...state, unapproved_transactions: action.payload };
		case types.SET_UNLIQ_LOANS:
			return { ...state, unliquidated_loans: action.payload };
		case types.SET_WITHDRAWALS:
			return { ...state, withdrawals: action.payload };
		case types.SET_LOAN_CANCEL_REQUESTS:
			return { ...state, cancel_requests: action.payload };	
		case types.SET_WALLET_DEBIT_REQUESTS:
			return { ...state, wallet_debit_requests: action.payload };			
		case types.SET_USSD_SETTINGS:
			return { ...state, ussd_settings: action.payload };			
		default:
			return state;
	}
};

export default user;
