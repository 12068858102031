import React from 'react';
import startCase from 'lodash.startcase';
import moment from 'moment';

const Payslip = ({ user, account }) => {
    return (
        <div className="users-list-w">
            <div className="user-w with-status status-green">
                <div className="user-name">
                    <h6 className="user-title">{user.lender ? user.lender.name : '-'}</h6>
                    <div className="user-role">LENDER NAME</div>
                </div>
                <div className="user-action">
                    <div className="os-icon os-icon-tasks-checked"/>
                </div>
            </div>
			<div className="user-w with-status status-green">
                <div className="user-name">
                    <h6 className="user-title">{user.ippis || '-'}</h6>
                    <div className="user-role">IPPIS NUMBER</div>
                </div>
                <div className="user-action">
                    <div className="os-icon os-icon-tasks-checked"/>
                </div>
            </div>
			<div className="user-w with-status status-green">
				<div className="user-name">
					<h6 className="user-title">{user.customer_id ? user.customer_id : '-'}</h6>
					<div className="user-role">CUSTOMER ID</div>
				</div>
				<div className="user-action">
					<div className="os-icon os-icon-tasks-checked"/>
				</div>
			</div>
			<div className="user-w with-status status-green">
				<div className="user-name">
					<h6 className="user-title">{moment(user.created_at).format('D.MMM.YYYY')}</h6>
					<div className="user-role">DATE REGISTERED</div>
				</div>
				<div className="user-action">
					<div className="os-icon os-icon-tasks-checked"/>
				</div>
			</div>
            {user && user.email && (
				<div className="user-w with-status status-green">
					<div className="user-name">
						<h6 className="user-title">{user.email}</h6>
						<div className="user-role">EMAIL ADDRESS</div>
					</div>
					<div className="user-action">
						<div className="os-icon os-icon-tasks-checked"/>
					</div>
				</div>
			)}
			<div className="user-w with-status status-green">
				<div className="user-name">
					<h6 className="user-title">{startCase(user.platform)}</h6>
					<div className="user-role">PLATFORM</div>
				</div>
				<div className="user-action">
					<div className="os-icon os-icon-tasks-checked"/>
				</div>
			</div>
            <div className="user-w with-status status-green">
				<div className="user-name">
					<h6 className="user-title">{user.employer || '-'}</h6>
					<div className="user-role">EMPLOYER</div>
				</div>
				<div className="user-action">
					<div className="os-icon os-icon-tasks-checked"/>
				</div>
			</div>
            {user.payslip && user.platform !== 'sme' && (
				<div className="user-w with-status status-red">
					<div className="user-name">
						<h6 className="user-title">{user.payslip.designation}</h6>
						<div className="user-role">DESIGNATION</div>
					</div>
					<div className="user-action">
						<div className="os-icon os-icon-tasks-checked"/>
					</div>
				</div>
			)}
			<div className="user-w with-status status-green">
				<div className="user-name">
					<h6 className="user-title">{startCase(user.gender)}</h6>
					<div className="user-role">GENDER</div>
				</div>
				<div className="user-action">
					<div className="os-icon os-icon-tasks-checked"/>
				</div>
			</div>
			<div className="user-w with-status status-green">
				<div className="user-name">
					<h6 className="user-title">{user.phone}</h6>
					<div className="user-role">PHONE NUMBER</div>
				</div>
				<div className="user-action">
					<div className="os-icon os-icon-tasks-checked"/>
				</div>
			</div>
            {user.payslip && user.platform !== 'sme' && (
				<div className="user-w with-status status-green">
					<div className="user-name">
						<h6 className="user-title">{user.payslip.first_appointment}</h6>
						<div className="user-role">DATE OF FIRST APPOINTMENT</div>
					</div>
					<div className="user-action">
						<div className="os-icon os-icon-tasks-checked"/>
					</div>
				</div>
			)}
            {user.payslip && user.platform !== 'sme' && (
				<div className="user-w with-status status-green">
					<div className="user-name">
						<h6 className="user-title">{user.payslip.retire_expected_at}</h6>
						<div className="user-role">EXPECTED DATE OF RETIREMENT</div>
					</div>
					<div className="user-action">
						<div className="os-icon os-icon-tasks-checked"/>
					</div>
				</div>
			)}
			{user && user.accountno && user.accountno.map((item, i) => {
				return (
					<div className="user-w with-status status-green" key={i}>
						<div className="user-name">
							<h6 className="user-title">{item.bank_name}</h6>
							<h6 className="user-title">{item.account_number}</h6>
							<div className="user-role">{`${item.status === 'primary' ? 'MAIN' : 'SECONDARY'} ACCOUNT`}</div>
						</div>
						<div className="user-action">
							<div className="os-icon os-icon-tasks-checked"/>
						</div>
					</div>
				)
			})}
            {user && (
				<div className="user-w with-status status-green">
					<div className="user-name">
						<h6 className="user-title">{user.bvn ? user.bvn : '-'}</h6>
						<div className="user-role">BVN</div>
					</div>
					<div className="user-action">
						<div className="os-icon os-icon-tasks-checked"/>
					</div>
				</div>
			)}
        </div>
    );
};

export default Payslip;