import * as types from '../actions/types';

const settings = (state = null, action) => {
	switch (action.type) {
		case types.PUT_SETTINGS:
			const settings = action.payload.reduce((map, obj) => {
				map[obj.name] = obj.value;
				return map;
			}, {});

			return settings;
		case types.SIGN_OUT:
			return null;
		default:
			return state;
	}
};

export default settings;
