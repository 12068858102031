import * as types from '../actions/types';

const INITIAL_STATE = {
	notifdata: null,
	notifdone: false,
	banks: [],
	slips: [],
	roles: [],
};

const general = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.SET_NOTIFICATION:
			return { ...state, notifdata: action.payload };
		case types.DO_NOTIFY_STATUS:
			return { ...state, notifdone: action.payload };
		case types.LOAD_BANKS:
			return { ...state, banks: action.payload };
		case types.SET_SLIPS:
			return { ...state, slips: [...action.payload] };
		case types.SET_ROLES:
			return { ...state, roles: [...action.payload] };
		case types.SIGN_OUT:
			return { ...state, ...INITIAL_STATE };
		default:
			return state;
	}
};

export default general;
