import React, { Component, Fragment } from 'react';
import Tooltip from 'antd/lib/tooltip';
import moment from 'moment';

import { currency } from '../config/constants';

class TransactionItem extends Component {
	state = {
		collapsed: true,
	};

	toggle = () => {
		this.setState({ collapsed: !this.state.collapsed });
	}

	render() {
		const { collapsed } = this.state;
		const { item, current_loan } = this.props;
		return (
			<Fragment>
				<tr>
					<td><div role="button" tabIndex="0" className={`row-expand-icon ${collapsed ? 'row-collapsed' : 'row-expanded'}`} onClick={this.toggle}/></td>
					<td>{item.loan.id}</td>
					<td><span className="smaller"><span className="bold">{item.loan.lender ? item.loan.lender.name : ''}</span></span></td>
					<td>{`${current_loan && current_loan.id === item.loan.id ? (item.loan.is_topup === 1 ? 'Current Topup-Loan' : 'Current Loan') : (item.loan.is_topup === 1 ? 'Topup-Loan' : 'Loan')} Request`}</td>
				</tr>
				{!collapsed && (
					<tr>
						<td colSpan="4">
							<table className="table table-striped">
								<thead>
								<tr>
									<th>Description</th>
									<th>Source</th>
									<th>Amount Paid</th>
									<th>Outst Amount</th>
									<th>Rep. Date</th>
									<th>Trt. Date</th>
									<th className="text-center">Status</th>
								</tr>
								</thead>
								<tbody>
									{item.transactions.map((d, i) => {
										const amount = d.description === 'Loan Taken' ? parseFloat(d.amount) - parseFloat(d.interest) : d.amount;
										return (
											<tr key={i}>
												<td>
													{`${d.loan.is_topup === 1 ? 'Topup' : ''} ${d.description || 'Pending Deduction'}`}{d.channel === 'staff-repayment' && <Tooltip title="Staff Repayment"><span className="text-primary ml-1" style={{fontSize: '17px'}}><i className="fa fa-info-circle"/></span></Tooltip>}
													{d.channel === 'credit-wallet' && (
														<Tooltip title="Staff Credit To Wallet"><span className="text-primary ml-1" style={{fontSize: '17px'}}><i className="fa fa-info-circle"/></span></Tooltip>
													)}
													{d.channel === 'wallet-repayments' && (
														<Tooltip title="Repayment From Wallet"><span className="text-primary ml-1" style={{fontSize: '17px'}}><i className="fa fa-info-circle"/></span></Tooltip>
													)}
													{d.channel === 'credit-wallet-repayment' && (
														<Tooltip title="Split Repayment"><span className="text-primary ml-1" style={{fontSize: '17px'}}><i className="fa fa-info-circle"/></span></Tooltip>
													)}
													{d.repayment_reference && d.repayment_reference !== '0' && <br/>}
													{d.repayment_reference && d.repayment_reference !== '0' && (
														<span className="bold smaller">{`Repayment Ref: ${d.repayment_reference || '-'}`}</span>
													)}
												</td>
												<td className="ucase">{d.repayment_source || '-'}</td>
												<td>{currency(amount)}</td>
												<td>{currency(d.outst_amount)}</td>
												<td nowrap="nowrap">{d.repayment_date ? moment(d.repayment_date, 'YYYY-MM-DD').format('DD-MMM-YYYY') : '-'}</td>
												<td nowrap="nowrap">{moment(d.created_at).format('DD-MMM-YYYY')}</td>
												<td className="text-center"><Tooltip placement="top" title={(d.loan.liquidate_approve === 0 && d.description === 'Loan Liquidated') || d.approved === 0 ? 'Pending Approval' : d.status}><div className={`status-pill ${(d.loan.liquidate_approve === 0 && d.description === 'Loan Liquidated') || d.approved === 0 ? 'yellow' : (d.status === 'Completed' ? 'green' : 'yellow')}`}/></Tooltip></td>
											</tr>
										)
									})}
									{item.transactions.length === 0 && (
										<tr><td colSpan="7">No transactions</td></tr>
									)}
								</tbody>
							</table>
						</td>
					</tr>
				)}
			</Fragment>
		);
	}
}

export default TransactionItem;