import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, reset, SubmissionError } from 'redux-form';

import { baseURL, httpRequest, userAPI } from '../config/constants';
import loading from '../assets/img/loading.gif';
import { doNotify } from '../actions/general';

const validate = values => {
    const errors = {}
    if (!values.old_customer_id) {
        errors.old_customer_id = 'Enter previous customer ID';
    }
	if (!values.customer_id) {
        errors.customer_id = 'Enter customer ID';
    }
    return errors;
};

const renderField = ({input, id, label, disabled, type, meta: {touched, error, warning}}) => (
    <div className={`form-group ${(touched && error && 'has-error')}`}>
        <label htmlFor={id}>{label}</label>
        <input {...input} placeholder={label} type={type} className="form-control" disabled={disabled} />
        {touched && error && <span className="help-block form-text with-errors form-control-feedback">{error}</span>}
    </div>
);

class EditCustomer extends Component {
    saveCustomer = async data => {
		try {
			const { user } = this.props;
            const rs = await httpRequest(`${baseURL}${userAPI}/${user.id}/update-cid`, 'POST', true, data);
			this.props.updateCustomerID(rs.user);
            // this.props.reset('savecustomer');
            this.notify('', 'customer id saved!', 'success');
            // this.props.closeModal();
        }
        catch (error) {
            const message = error.message || 'could not save customer id';
            this.notify('', message, 'error');
            throw new SubmissionError({
                _error: message,
            });
        }
    };
    
    notify = (title, message, type) => {
		this.props.doNotify({
			message,
			level: type,
			title,
		});
	};

    render() {
        const { closeModal, handleSubmit, error, submitting, pristine } = this.props;
        return (
            <div>
                <div className="modal-header faded smaller">
                    <div className="modal-title">
                        <span>Save Customer ID</span>
                    </div>
                    <button aria-label="Close" className="close" onClick={closeModal} type="button"><span aria-hidden="true"> ×</span></button>
                </div>
                <form onSubmit={handleSubmit(this.saveCustomer)}>
                    <div className="modal-body">
                        {error && <div className="alert alert-danger" style={{marginBottom: '15px'}} dangerouslySetInnerHTML={{__html: `<strong>Error!</strong> ${error}`}}/>}
                        <div className="row">
                            <div className="col-sm-12">
                                <Field
                                    name="old_customer_id"
                                    id="old_customer_id"
                                    type="number"
                                    component={renderField}
                                    label="Previous Customer ID"
									disabled={true}
                                />
                            </div>
                        </div>
						<div className="row">
                            <div className="col-sm-12">
                                <Field
                                    name="customer_id"
                                    id="customer_id"
                                    type="number"
                                    component={renderField}
                                    label="New Customer ID"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer buttons-on-left">
                        <button className="btn btn-teal" disabled={pristine || submitting} type="submit">
                            {submitting? <img src={loading} alt=""/> : 'Create'}
                        </button>
                        <button className="btn btn-link cursor" onClick={closeModal} type="button"> Cancel</button>
                    </div>
                </form>
            </div>
        );
    }
}

EditCustomer = reduxForm({
    form: 'savecustomer',
    validate,
})(EditCustomer);

const mapStateToProps = (state, ownProps) => {
    return {
        initialValues: {
            old_customer_id: ownProps.user?.customer_id || '',
            customer_id: "",
        },
    }
}

export default connect(mapStateToProps, { reset, doNotify })(EditCustomer);
