import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';

import user from './user';
import loan from './loan';
import settings from './settings';
import merchant from './merchant';
import schedules from './schedule';
import uiblock from './ui-block';
import general from './general';
import support from './support';
import notifications from './notification';
import mail from './mail';
import lender from './lender';

const reducers = combineReducers({
	uiblock,
	form: formReducer,
	routing: routerReducer,
	user,
	loan,
	settings,
	merchant,
	schedules,
	general,
	support,
	notifications,
	mail,
	lender,
});

export default reducers;
