import * as types from './types';

export const loadMail = data => {
	return {
		type: types.LOAD_MAIL,
		payload: data,
	};
};

export const pickMail = data => {
	return {
		type: types.SELECT_MAIL,
		payload: data,
	};
};

export const addMail = data => {
	return {
		type: types.ADD_MAIL,
		payload: data,
	};
};
