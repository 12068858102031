import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, reset, SubmissionError, formValueSelector } from 'redux-form';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import { baseURL, httpRequest, walletAPI } from '../config/constants';
import loading from '../assets/img/loading.gif';
import { doNotify } from '../actions/general';

const validate = values => {
    const errors = {}
    if (!values.amount) {
        errors.amount = 'Enter the amount';
    }
    if (values.source === 'Remita' && !values.repayment_reference) {
        errors.repayment_reference = 'Enter repayment reference';
    }
    return errors;
};

const renderField = ({input, id, label, type, meta: {touched, error, warning}}) => (
    <div className={`form-group ${(touched && error && 'has-error')}`}>
        <label htmlFor={id}>{label}</label>
        <input {...input} placeholder={label} type={type} className="form-control" />
        {touched && error && <span className="help-block form-text with-errors form-control-feedback">{error}</span>}
    </div>
);

const renderSelectField = ({input, label, data, meta: {touched, error}}) => (
    <div className={`form-group ${(touched && error && 'has-error')}`}>
        <label>{label}</label>
        <select {...input} className="form-control">
            {data.map((item, i) => <option value={item} key={i}>{item}</option>)}
        </select>
        {touched && error && <span className="help-block form-text with-errors form-control-feedback">{error}</span>}
    </div>
);

class CreditWallet extends Component {
    state = {
		date_error: '',
		repaymentDate: null,
		crediting: false,
	};

	close = () => {
		this.setState({ date_error: '', repaymentDate: null });
		this.props.reset('creditwallet');
		this.props.doHide();
	}
	
	credit = async (data) => {
		this.setState({ date_error: '' });
		const action = window.confirm('Will you like to credit this wallet?')
			? true : false;
		if (!action) {
			return;
		}

		try {
			const { user, loan, doHide, updateWallet } = this.props;
			const { repaymentDate } = this.state;
			if (!repaymentDate) {
				this.setState({ date_error: 'select repayment date' });
				return;
			}

			this.setState({ crediting: true });

			const datum = {
				...data,
				staff_id: user.id,
				repayment_date: repaymentDate.format('YYYY-MM-DD'),
			};
			const url = `${baseURL}${walletAPI}/credit/${loan.id}`;
			const rs = await httpRequest(url, 'POST', true, datum);

			this.props.reset('creditwallet');

			updateWallet(rs);

			this.setState({ crediting: false });
			this.notify('', 'wallet credited successfully', 'success');
			doHide();
		} catch (error) {
			const message = error.message || 'could not credit wallet';
			this.notify('', message, 'error');
			this.setState({ crediting: false });
			throw new SubmissionError({
				_error: message,
			});
		}
	};

	notify = (title, message, type) => {
		this.props.doNotify({
			message,
			level: type,
			title,
		});
	};

	handleChange = (date) => this.setState({ repaymentDate: date });

	render() {
        const { handleSubmit, error, pristine, source } = this.props;
        const { date_error, repaymentDate, crediting } = this.state;
		return (
			<div className="element-box p-3 m-0" style={{width: '360px'}}>
                <form onSubmit={handleSubmit(this.credit)}>
					{error && <div className="alert alert-danger" style={{marginBottom: '15px'}} dangerouslySetInnerHTML={{__html: `<strong>Error!</strong> ${error}`}}/>}
					<div className="row">
						<div className="col-sm-6">
							<Field
								name="amount"
								id="amount"
								type="text"
								component={renderField}
								label="Amount"
							/>
						</div>
						<div className="col-sm-6">
							<div className={`form-group row ${(date_error !== '' && 'has-error')}`} style={{marginLeft: 0, marginRight: 0}}>
								<label style={{display: 'block', width: '100%'}}>Repayment Date</label>
								<DatePicker
									selected={repaymentDate}
									onChange={this.handleChange}
									dateFormat="DD-MMM-YYYY"
									placeholderText="Click to select date"
									className="form-control white-bg width-100"
								/>
								{date_error !== '' && <span className="help-block form-text with-errors form-control-feedback">{date_error}</span>}
							</div>
						</div>
					</div>
					<div className="row">
						<div className={source === 'Remita' ? 'col-sm-6':'col-sm-12'}>
							<Field
								name="source"
								id="source"
								component={renderSelectField}
								label="Repayment Source"
								data={['IPPIS', 'Remita']}
							/>
						</div>
						{source === 'Remita' && (
							<div className="col-sm-6">
								<Field
									name="repayment_reference"
									id="repayment_reference"
									type="text"
									component={renderField}
									label="Repayment Reference"
								/>
							</div>
						)}
					</div>
                    <div className="text-center">
                        <button className="btn btn-primary" disabled={pristine || crediting} type="submit">
                            {crediting? <img src={loading} alt=""/> : 'Credit'}
                        </button>
                        <button className="btn btn-default ml-4" onClick={this.close} type="button"> Cancel</button>
                    </div>
                </form>
            </div>
		);
	}
}

CreditWallet = reduxForm({
    form: 'creditwallet',
    validate,
})(CreditWallet);

const selector = formValueSelector('creditwallet');

const mapStateToProps = (state, ownProps) => {
    return {
        initialValues: {
            source: 'Remita',
		},
		source: selector(state, 'source'),
		user: state.user.user,
    }
}

export default connect(mapStateToProps, { reset, doNotify })(CreditWallet);
