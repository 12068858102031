// users
export const SIGN_OUT = 'SIGN_OUT';
export const START_PRELOADING = 'START_PRELOADING';
export const STOP_PRELOADING = 'STOP_PRELOADING';
export const CHANGE_USER_DATA = 'CHANGE_USER_DATA';
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const SHOW_PROFILE = 'SHOW_PROFILE';
export const CLOSE_PROFILE = 'CLOSE_PROFILE';
export const LOAD_CUSTOMERS = 'LOAD_CUSTOMERS';
export const SET_USER_PAYSLIP = 'SET_USER_PAYSLIP';
export const SET_UNAPPROVED_TRANSACTIONS = 'SET_UNAPPROVED_TRANSACTIONS';
export const SET_UNLIQ_LOANS = 'SET_UNLIQ_LOANS';
export const SET_EDIT_CUSTOMER = 'SET_EDIT_CUSTOMER';
export const SET_WITHDRAWALS = 'SET_WITHDRAWALS';
export const SET_LOAN_CANCEL_REQUESTS = 'SET_LOAN_CANCEL_REQUESTS';
export const SET_WALLET_DEBIT_REQUESTS = 'SET_WALLET_DEBIT_REQUESTS';
export const SET_REMITA_PAYSLIP = 'SET_REMITA_PAYSLIP';

// general
export const DO_NOTIFY_STATUS = 'DO_NOTIFY_STATUS';
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const LOAD_BANKS = 'LOAD_BANKS';
export const SET_SLIPS = 'SET_SLIPS';
export const SET_ROLES = 'SET_ROLES';

// ui-block
export const REQUEST_START = 'REQUEST_START';
export const REQUEST_STOP = 'REQUEST_STOP';

// settings
export const PUT_SETTINGS = 'PUT_SETTINGS';
export const UPDATE_WALLET_AMOUNT = 'UPDATE_WALLET_AMOUNT';

// loan
export const SET_LOANS = 'SET_LOANS';
export const SET_LOAN_ANALYTICS = 'SET_LOAN_ANALYTICS';
export const SET_USSD_SETTINGS = 'SET_USSD_SETTINGS';
export const UPDATE_LOAN = 'UPDATE_LOAN';
export const DECLINE_LOAN = 'DECLINE_LOAN';
export const UPDATE_LOAN_ANALYTICS = 'UPDATE_LOAN_ANALYTICS';
export const SET_TENURE = 'SET_TENURE';
export const SET_LOAN_EARNINGS = 'SET_LOAN_EARNINGS';
export const REFRESH_LOAN = 'REFRESH_LOAN';
export const SET_NET_EARNING = 'SET_NET_EARNING';
export const SET_MONTHLY_REPAYMENT = 'SET_MONTHLY_REPAYMENT';
export const SET_ELIGIBLE_AMT = 'SET_ELIGIBLE_AMT';
export const SET_MONTHLY_COMMISSION = 'SET_MONTHLY_COMMISSION';
export const RESET_LOAN_DATA = 'RESET_LOAN_DATA';
export const SET_DESIRED_AMOUNT = 'SET_DESIRED_AMOUNT';
export const SET_DESIRED_MONTHLY_REPAYMENT = 'SET_DESIRED_MONTHLY_REPAYMENT';
export const SET_MONTHLY_PRINCIPAL = 'SET_MONTHLY_PRINCIPAL';
export const SET_MONTHLY_INTEREST = 'SET_MONTHLY_INTEREST';
export const SET_DISBURSE_AMOUNT = 'SET_DISBURSE_AMOUNT';
export const SET_REMITA_ID = 'SET_REMITA_ID';

// merchant
export const SET_MERCHANT = 'SET_MERCHANT';
export const ADD_MERCHANT = 'ADD_MERCHANT';
export const LOAD_MERCHANTS = 'LOAD_MERCHANTS';
export const DELETE_MERCHANT = 'DELETE_MERCHANT';
export const UPDATE_MERCHANT = 'UPDATE_MERCHANT';

// schedule
export const LOAD_SCHEDULES = 'LOAD_SCHEDULES';

// transaction
export const LOAD_TRANSACTIONS = 'LOAD_TRANSACTIONS';
export const SET_TRANSACTION = 'SET_TRANSACTION';

// support
export const LOAD_SUPPORT = 'LOAD_SUPPORT';
export const NEW_SUPPORT = 'NEW_SUPPORT';
export const UPDATE_SUPPORT = 'UPDATE_SUPPORT';
export const TOGGLE_UPDATED = 'TOGGLE_UPDATED';
export const UNREAD_SUPPORT = 'UNREAD_SUPPORT';

// notifications
export const LOAD_NOTIFICATIONS = 'LOAD_NOTIFICATIONS';

// mail
export const LOAD_MAIL = 'LOAD_MAIL';
export const ADD_MAIL = 'ADD_MAIL';
export const SELECT_MAIL = 'SELECT_MAIL';
export const UPDATE_MAIL = 'UPDATE_MAIL';
export const DELETE_MAIL = 'DELETE_MAIL';

// lender
export const SET_LENDER_PROFILE = 'SET_LENDER_PROFILE';
export const EDIT_LENDER_PROFILE = 'EDIT_LENDER_PROFILE';
export const LOAD_LENDERS = 'LOAD_LENDERS';
export const ADD_LENDER = 'ADD_LENDER';
