import * as types from './types';

export const toggleMenu = (action) => {
	return {
		type: types.TOGGLE_MENU,
		payload: action,
	};
};

export const signOut = () => {
	return {
		type: types.SIGN_OUT,
	};
};

export const startPreloading = () => {
	return {
		type: types.START_PRELOADING,
	};
};

export const stopPreloading = () => {
	return {
		type: types.STOP_PRELOADING,
	};
};

export const changeUserData = (user, status) => {
	return {
		type: types.CHANGE_USER_DATA,
		user,
		status,
	};
};

export const loadCustomers = (data) => {
	return {
		type: types.LOAD_CUSTOMERS,
		payload: data,
	};
};

export const setUserPayslip = (data) => {
	return {
		type: types.SET_USER_PAYSLIP,
		payload: data,
	};
};

export const setRemitaPayslip = (data) => {
	return {
		type: types.SET_REMITA_PAYSLIP,
		payload: data,
	};
};

export const setUnapprovedTransactions = (data) => {
	return {
		type: types.SET_UNAPPROVED_TRANSACTIONS,
		payload: data,
	};
};

export const setUnliquidatedLoans = (data) => {
	return {
		type: types.SET_UNLIQ_LOANS,
		payload: data,
	};
};

export const editCustomer = (data) => {
	return {
		type: types.SET_EDIT_CUSTOMER,
		payload: data,
	};
};

export const setWithdrawals = (data) => {
	return {
		type: types.SET_WITHDRAWALS,
		payload: data,
	};
};

export const setLoanCancelRequests = (data) => {
	return {
		type: types.SET_LOAN_CANCEL_REQUESTS,
		payload: data,
	};
};

export const setWalletDebitRequests = (data) => {
	return {
		type: types.SET_WALLET_DEBIT_REQUESTS,
		payload: data,
	};
};
