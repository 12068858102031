import React from 'react';

import loader from '../assets/img/loader.gif';

const Loading = () => {
	return (
		<div className="content-i">
			<div className="content-box" style={{ padding: '20px', position: 'relative', height: '210px' }}>
				<div className="loading-page">
					<img src={loader} alt="" />
				</div>
			</div>
		</div>
	);
};

export default Loading;