/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import split from 'lodash.split';
import template from 'lodash.template';
import size from 'lodash.size';
import debounce from 'lodash.debounce';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { SHOW_PROFILE } from '../actions/types';
import { httpRequest, baseURL, searchAPI } from '../config/constants';
import searchingGIF from '../assets/img/loading-2.gif';

const compiled = template('<span><%= phone %></span>');

class Search extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			search: '',
			focused: false,
			users: [],
			searching: false,
		};
		this.handleChange = this.handleChange.bind(this);
		this.doSearch = this.doSearch.bind(this);
		this.changed = debounce(this.doSearch, 250);
		this.showProfile = this.showProfile.bind(this);
	}
	
	handleChange = (e) => {
		const search = e.target.value;
		this.setState({ search }, () => {
			this.changed(search);
		});
	};

	doSearch = async e => {
		const { lender } = this.props;
		if(size(e) > 3){
			this.setState({ searching: true, error: false });
			try {
				const response = await httpRequest(`${baseURL}${searchAPI}?q=${e}&lender=${lender.id}`, 'GET', true);
				this.setState({ searching: false, users: response.users });
			}
			catch (error) {
				this.setState({ searching: false, users: [], error: true });
			}
		}
		else {
			this.setState({ users: [] });
		}
	};

	componentWillUpdate(nextProps, nextState) {
		if(nextProps.focus && !nextState.focused) {
			this.setFocus();
		}
		if(nextProps.style.display === 'none' && nextState.focused){
			this.setState({ focused: false, search: '', users: [] });
		}
	}

	setFocus = () => {
		this.setState({ focused: true });
		setTimeout(() => {
			this.searchInput.click();
			this.searchInput.focus();
		}, 10);
	}

	showProfile = (id) => () => this.props.doShowProfile({ show_profile: true, user_id: id });
	
	render() {
		const { search, users, searching } = this.state;
		const { style, onExit } = this.props;
		return (
			<div className="search-with-suggestions-w over-search-field" style={style}>
				<div className="search-with-suggestions-modal">
					<div className="element-search" style={{marginBottom: 0}}>
						<input className="search-suggest-input" ref={input => this.searchInput = input} placeholder="Start typing to search..." type="text" value={search} onChange={this.handleChange}/>
						<div className="close-search-suggestions" onClick={onExit}>
							<i className="os-icon os-icon-x"/>
						</div>
					</div>
					{searching && (
						<div className="searching"><img alt="searching" src={searchingGIF} /></div>
					)}
					{!searching && (
						<div className="search-suggestions-group" style={{marginTop: '30px'}}>
							<div className="ssg-header">
								<div className="ssg-icon">
									<div className="os-icon os-icon-users"></div>
								</div>
								<div className="ssg-name">Customers</div>
								<div className="ssg-info">{users.length} Total</div>
							</div>
							<div className="ssg-content">
								<div className="ssg-items ssg-items-list">
									{users.length === 0 && <small>No Customer Found!</small>}
									{users.map(u => {
										const p = split(u.phone, search);
										return (
											<div style={{display: 'flex', justifyContent: 'space-between'}} key={u.id}>
												<a onClick={this.showProfile(u.id)} className="ssg-item cursor">
													<div className="item-name" dangerouslySetInnerHTML={{__html: `${u.name} - ${p.length === 1 ? u.phone : `${p[0]}${compiled({'phone': search})}${p[1]}`}`}}/>
												</a>
												<Link to={`/offices/view/${u.office_id}?search=${u.phone}&p=1&office=${u.office_name}`} style={{width: '24px', textAlign: 'center'}}><i className="os-icon os-icon-mail-18"/></Link>
											</div>
										)
									})}
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		lender: state.lender.profile,
	}
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		doShowProfile: (data) => dispatch({ type: SHOW_PROFILE, data }),
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);