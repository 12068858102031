import * as types from './types';

export const loadSupport = data => {
	return {
		type: types.LOAD_SUPPORT,
		payload: data,
	};
};

export const addSupport = data => {
	return {
		type: types.NEW_SUPPORT,
		payload: data,
	};
};

export const updateTicket = data => {
	return {
		type: types.UPDATE_SUPPORT,
		payload: data,
	};
};

export const toggleUpdated = () => {
	return {
		type: types.TOGGLE_UPDATED,
	};
};

export const setUnreadSupport = length => {
	return {
		type: types.UNREAD_SUPPORT,
		payload: length,
	};
};
