/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Popover from 'antd/lib/popover';
import loader from '../assets/img/loader.gif';
import loading from '../assets/img/loading.gif';
import profile_bg1 from '../assets/img/profile_bg1.jpg';
import Payslip from './Payslip';
import LoanHistory from './LoanHistory';
import LoanTransactions from './LoanTransactions';
import { currency, walletAPI, httpRequest, baseURL } from '../config/constants';
import WalletHistory from '../modals/WalletHistory';
import EditCustomer from '../modals/EditCustomer';
import { doNotify } from '../actions/general';

const EditWallet = ({ submitting, doHide, onChangeAmount, changeWallet, reason, onChangeReason }) => {
	return (
		<div>
			<form onSubmit={(e) => changeWallet(e)}>
				<div className="row p-3">
					<div className="col-sm-12">
						<div className="form-group">
							<input type="text" placeholder='Amount' onChange={(e) => onChangeAmount(e)} className='form-control'/>
						</div>
						<div className="form-group">
							<textarea
								name="reason"
								placeholder="Narration"
								type="text"
								className="form-control"
								onChange={(e) => onChangeReason(e)}
								rows="3"
								value={reason}
							></textarea>
						</div>
						
						<button
						className="mr-2 btn btn-primary"
						disabled={submitting}
						type="submit"
						>
							{submitting ? <img src={loading} alt="" /> : 'Proceed'}
						</button>
						<button className="btn btn-default" type="button" onClick={doHide}>
							Cancel
						</button>
					</div>
				</div>
			</form>
		</div>
	)
}

const Profile = ({ closeModal, user, fetching, transactions, updateWallet, updateUser, updateCustomerID, staff }) => {
	const [modalOpen, setModalOpen] = useState(false);
	const [modalEditCustomerOpen, setModalEditCustomerOpen] = useState(false);
	const [visible, setVisible] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [walletAmount, setWalletAmount] = useState(null);
	const [reason, setReason] = useState('');

	const dispatch = useDispatch();

	const viewWalletHistory = () => {
		setModalOpen(true);
	}

	const editCustomerId = () => {
		setModalEditCustomerOpen(true);
	}

	const closeWalletModal = () => {
		setModalOpen(false);
		setModalEditCustomerOpen(false);
	}

	const showEditWallet = (status) => {
		setVisible(status);
		resetForm()
	}

	const doHide = () => {
		setVisible(false);
		resetForm()
	};

	const resetForm = () => {
		setWalletAmount('');
		setReason('')
	}

	const onChangeAmount = e => setWalletAmount(e.target.value);	

	const onChangeReason = e => setReason(e.target.value)

	const changeWallet = async e => {
		try {
			e.preventDefault();

			if(reason.trim().length < 1 || walletAmount.length < 1){
				dispatch(doNotify({ message: 'Enter both fields', level: 'error', title: ''}));
				return;
			}

			const action = window.confirm('Are your sure?') ? true : false;
			if (!action) {
				return;
			}

			setSubmitting(true)		
			let body = { amount: walletAmount, reason, staff_id: staff.id }
			const url = `${baseURL}${walletAPI}/debit-request/${user.id}`;
			const rs = await httpRequest(url, 'POST', true, body);
			dispatch(doNotify({ message: 'Debit request has been sent', level: 'success', title: 'Debit request'}));

			updateUser(rs.user)
			setSubmitting(false)
			resetForm();
		} catch (error) {
			console.log(error);
			setSubmitting(false)
			const message = error.message || 'Could not send debit request';
			dispatch(doNotify({ message: message, level: 'success', title: null }));
		}
	}

	return (
		<>
			{fetching && !user ? <div className="fetching-load"><img alt="fetching" src={loader} /></div> : 
			(
				<div className="element-box" style={{padding: '1.5rem 0.4rem'}}>
					<div className="col-sm-12">
						<div className="row" style={{border: 'hidden'}}>
							<div className="col-sm-3">
								<div className="user-profile compact">
									<div className="up-head-w" style={{backgroundImage: 'url('+profile_bg1+')'}}>
										<div className="up-main-info">
											<h2 className="up-header">{user.name}</h2>
										</div>
										<svg className="decor" width="842px" height="219px" viewBox="0 0 842 219"
											preserveAspectRatio="xMaxYMax meet" version="1.1"
											xmlns="http://www.w3.org/2000/svg"
											xmlnsXlink="http://www.w3.org/1999/xlink">
											<g transform="translate(-381.000000, -362.000000)" fill="FFFFFF">
												<path className="decor-path"
													d="M1223,362 L1223,581 L381,581 C868.912802,575.666667 1149.57947,502.666667 1223,362 Z"></path>
											</g>
										</svg>
									</div>
									<div className="up-contents">
										<div className="element-wrapper">
											<h6 className="element-header" style={{marginTop: '1.5rem'}}>&nbsp;</h6>
											<div className="element-box-tp">
												<Payslip user={user} />
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-sm-9">
								<div className="row">
									<div className="col-sm-12">
										{user?.customer_id && (
											<div className="element-actions">
												<a className="btn btn-primary btn-sm btn-edit pointer" role="button" tabIndex="1" onClick={() => editCustomerId()}><span>Edit Customer</span></a>
											</div>
										)}
										<h6 className="element-header" style={{marginTop: 0}}>Profile Data</h6>
										<div className="table-responsive">
											<table className="table table-striped">
												<tbody>
													<tr><th>Address</th><td colSpan="3">{user.address1}</td></tr>
													<tr><th>Next of Kin</th><td colSpan="3">{`${user.nok_firstname || ''} ${user.nok_lastname || ''}`}</td></tr>
													<tr>
														<th>Next of Kin Phone</th><td>{user.nok_phone}</td>
														<th>Next of Kin Relationship</th><td>{user.nok_relationship}</td>
													</tr>
													<tr><th>Next of Kin Address</th><td colSpan="3">{user.nok_address1}</td></tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-12">
										<h6 className="element-header" style={{marginTop: '12px'}}>Wallet</h6>
										<div className="table-responsive">
											<table className="table table-striped">
												<tbody>
													<tr>
														<th>Balance</th>
														<td>{currency(user.wallet)}</td>
														<td><a className="link cursor" onClick={() => viewWalletHistory()}>view history</a></td>
														<td>
														<Popover
															title=""
															content={
																<EditWallet
																	doHide={doHide}
																	submitting={submitting}
																	onChangeAmount={onChangeAmount}
																	changeWallet={changeWallet}
																	reason={reason} 
																	onChangeReason={onChangeReason}
																/>
															}
															trigger="click"
															visible={visible}
															onVisibleChange={(status) => showEditWallet(status)}
														>
															<a disabled={user.debit_request === 1} className="btn btn-info btn-sm pointer" role="button" tabIndex="1"><span>{user.debit_request === 1 ? 'Pending Request' : 'Debit Wallet Request'}</span></a>
														</Popover>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
								<LoanHistory
									loans={user.loans}
									title="Loan History"
									current_loan={user.loan}
									user={user}
									updateWallet={updateWallet}
								/>
								<LoanTransactions
									user={user}
									transactions={transactions}
									current_loan={user.loan}
									updateWallet={updateWallet}
								/>
							</div>
						</div>
					</div>
				</div>
			)}
			<div className="modal-footer">
				<button className="btn btn-teal" onClick={closeModal} type="button"> Close</button>
			</div>
			{modalOpen && (
				<>
					<div className="ReactModalPortal" style={{zIndex: '1065'}}>
						<div className="ReactModal__Overlay ReactModal__Overlay--after-open modal-dialog modal-md">
							<div
								className="ReactModal__Content ReactModal__Content--after-open modal-content"
								role="dialog"
							>
								<WalletHistory
									user={user}
									closeModal={closeWalletModal}
									wallet={user.wallet}
								/>
							</div>
						</div>
					</div>
					<div className="modal-backdrop fade show" style={{zIndex: '1060', opacity: '0.1'}} />
				</>
			)}
			{modalEditCustomerOpen && (
				<>
					<div className="ReactModalPortal" style={{zIndex: '1065'}}>
						<div className="ReactModal__Overlay ReactModal__Overlay--after-open modal-dialog modal-sm">
							<div
								className="ReactModal__Content ReactModal__Content--after-open modal-content"
								role="dialog"
							>
								<EditCustomer
									user={user}
									closeModal={closeWalletModal}
									updateCustomerID={updateCustomerID}
								/>
							</div>
						</div>
					</div>
					<div className="modal-backdrop fade show" style={{zIndex: '1060', opacity: '0.1'}} />
				</>
			)}
		</>
	);
};

export default Profile;