import * as types from './types';
import { httpRequest, baseURL, notificationAPI } from '../config/constants';
import { stopPreloading } from './user';

export const loadNotifications = (user) => {
	return async (dispatch) => {
		const lenderId = user.lender_id;
		try {
			// prettier-ignore
			const response = await httpRequest(`${baseURL}${notificationAPI}?lender=${lenderId}`, 'GET', true);
			dispatch({
				type: types.LOAD_NOTIFICATIONS,
				payload: response.notifications.filter((n) => n.loan !== null),
			});
			dispatch(stopPreloading());
		} catch (error) {
			dispatch(stopPreloading());
			console.log(error);
		}
	};
};
