import * as types from '../actions/types';

const notifications = (state = [], action) => {
	switch (action.type) {
		case types.LOAD_NOTIFICATIONS:
			return [...action.payload];
		case types.SIGN_OUT:
			return [];
		default:
			return state;
	}
};

export default notifications;
