/* eslint eqeqeq: 0 */
import * as types from '../actions/types';

const INITIAL_STATE = {
	list: [],
	merchant: null,
};

const merchant = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.SET_MERCHANT:
			return { ...state, merchant: action.payload };
		case types.LOAD_MERCHANTS:
			return { ...state, list: [...action.payload] };
		case types.ADD_MERCHANT:
			return { ...state, list: [...state.list, action.payload] };
		case types.UPDATE_MERCHANT:
			const merchant = state.list.find(u => u.id == action.payload.id);
			const merch = { ...merchant, count: action.payload.count };
			return { ...state, list: [...state.list.filter(u => u.id != action.payload.id), merch] };
		case types.DELETE_MERCHANT:
			return { ...state, list: [...state.list.filter(u => u.id != action.payload)] };
		case types.SIGN_OUT:
			return { ...state, ...INITIAL_STATE };
		default:
			return state;
	}
};

export default merchant;
