import * as types from '../actions/types';

const INITIAL_STATE = {
	list: [],
	updated: false,
	unread: 0,
};

const support = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.LOAD_SUPPORT:
			return { ...state, list: [...action.payload] };
		case types.NEW_SUPPORT:
			return { ...state, list: [action.payload, ...state.list] };
		case types.UPDATE_SUPPORT:
			const payload = action.payload;
			const ticket = state.list.find(l => l.id === payload.reply_id);
			if (ticket) {
				const index = state.list.findIndex(l => l.id === payload.reply_id);
				return {
					...state,
					list: [
						...state.list.slice(0, index),
						{
							...ticket,
							status: payload.status,
							replies: [...payload.replies],
						},
						...state.list.slice(index + 1),
					],
					updated: true,
				};
			}
			return state;
		case types.TOGGLE_UPDATED:
			return { ...state, updated: false };
		case types.UNREAD_SUPPORT:
			return { ...state, unread: action.payload };
		case types.SIGN_OUT:
			return { ...state, ...INITIAL_STATE };
		default:
			return state;
	}
};

export default support;
