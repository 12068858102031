/* eslint eqeqeq: 0 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter, Link } from 'react-router-dom';
import ReduxBlockUi from 'react-block-ui/redux';
import Loadable from 'react-loadable';
import NotificationSystem from 'react-notification-system';
import moment from 'moment';
import $ from 'jquery';
import startCase from 'lodash.startcase';
import numeral from 'numeral';

import loader from './assets/img/loader.gif';
import Loading from './components/Loading';
import { doNotify } from './actions/general';
import UserProfile from './components/UserProfile';
import Search from './components/Search';
import DesktopMenu from './components/DesktopMenu';
import logo from './assets/img/fc-logo.png';
import card from './assets/img/card1.png';
import profile from './assets/img/avatar1.jpg';
import ScrollToTop from './container/ScrollToTop';
import NotifyItem from './components/NotifyItem';

const SignIn = Loadable({ loader: () => import('./pages/SignIn'), loading: Loading });
const ForgotPassword = Loadable({ loader: () => import('./pages/ForgotPassword'), loading: Loading });
const ResetPassword = Loadable({ loader: () => import('./pages/ResetPassword'), loading: Loading });
const Logout = Loadable({ loader: () => import('./pages/Logout'), loading: Loading });
const NoMatch = Loadable({ loader: () => import('./pages/NoMatch'), loading: Loading });
const Dashboard = Loadable({ loader: () => import('./pages/Dashboard'), loading: Loading });
const Users = Loadable({ loader: () => import('./pages/Users'), loading: Loading });
const AdminUsers = Loadable({ loader: () => import('./pages/AdminUsers'), loading: Loading });
const Offices = Loadable({ loader: () => import('./pages/Offices'), loading: Loading });
const Merchants = Loadable({ loader: () => import('./pages/Merchants'), loading: Loading });
const Transactions = Loadable({ loader: () => import('./pages/Transactions'), loading: Loading });
const Settings = Loadable({ loader: () => import('./pages/Settings'), loading: Loading });
const Report = Loadable({ loader: () => import('./pages/Report'), loading: Loading });
const ViewMerchant = Loadable({ loader: () => import('./pages/ViewMerchant'), loading: Loading });
const Support = Loadable({ loader: () => import('./pages/Support'), loading: Loading });
const VerifyRequests = Loadable({ loader: () => import('./pages/VerifyRequests'), loading: Loading });
const ApproveRequests = Loadable({ loader: () => import('./pages/ApproveRequests'), loading: Loading });
const DefaultRequests = Loadable({ loader: () => import('./pages/DefaultRequests'), loading: Loading });
const Mail = Loadable({ loader: () => import('./pages/Mail'), loading: Loading });
const Lenders = Loadable({ loader: () => import('./pages/Lenders'), loading: Loading });
const ApproveLiquidatedLoans = Loadable({ loader: () => import('./pages/ApproveLiquidatedLoans'), loading: Loading });
const LoanHistory = Loadable({ loader: () => import('./pages/LoanHistory'), loading: Loading });
const CreateUser = Loadable({ loader: () => import('./pages/CreateUser'), loading: Loading });
const LoanRequest = Loadable({ loader: () => import('./pages/LoanRequest'), loading: Loading });
const ApproveTransactions = Loadable({ loader: () => import('./pages/ApproveTransactions'), loading: Loading });
const ChangePassword = Loadable({ loader: () => import('./pages/ChangePassword'), loading: Loading });
const MultiFunding = Loadable({ loader: () => import('./pages/MultiFunding'), loading: Loading });
const EditUser = Loadable({ loader: () => import('./pages/EditUser'), loading: Loading });
const ActiveLoans = Loadable({ loader: () => import('./pages/ActiveLoans'), loading: Loading });
const WithdrawalRequests = Loadable({ loader: () => import('./pages/WithdrawalRequests'), loading: Loading });
const CancelLoanRequests = Loadable({ loader: () => import('./pages/CancelLoanRequest'), loading: Loading });
const WalletDebitRequest = Loadable({ loader: () => import('./pages/WalletDebitRequest'), loading: Loading });
const Audit = Loadable({ loader: () => import('./pages/Audit'), loading: Loading });
const EditLoan = Loadable({ loader: () => import('./pages/EditLoan'), loading: Loading });

class App extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			notified: false,
			style: { display: 'none' },
			focus: false,
			hovered: false,
		};
		this.notifRef = null;

		this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
		this.onExit = this.onExit.bind(this);
		this.onFocus = this.onFocus.bind(this);
	}

	componentDidMount() {
		document.body.className = 'pages';
	}

	enter = () => {
		this.setState({ hovered: true });
	};

	leave = () => {
		this.setState({ hovered: false });
	};

	componentWillUpdate(nextProps, nextState) {
		if (!nextState.notified && nextProps.notifdata) {
			const { title, message, level } = nextProps.notifdata;
			const id = moment().format('X');
			this.notify(title, message, level, id);
			this.setState({ notified: true });
			this.props.doNotify(null);
		}
	}

	notify = (title, message, level, id) => {
		this.refs.notifRef.addNotification({
			title,
			message,
			level,
			autoDismiss: 4,
			uid: id,
			onRemove: this.onRemove,
		});
	};

	onRemove = () => this.setState({ notified: false });

	getCoords = elem => {
		// crossbrowser version
		var box = elem.getBoundingClientRect();

		var body = document.body;
		var docEl = document.documentElement;

		var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
		var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

		var clientTop = docEl.clientTop || body.clientTop || 0;
		var clientLeft = docEl.clientLeft || body.clientLeft || 0;

		var top = box.top + scrollTop - clientTop;
		var left = box.left + scrollLeft - clientLeft;

		return { top: Math.round(top) - 10, left: Math.round(left) - 100 };
	};

	toggleMobileMenu = e => {
		e.preventDefault();
		$(this.refs.mmenu).toggleClass('show-mobile');
	};

	onFocus = e => {
		const style = {
			display: 'block',
		};
		const coords = this.getCoords(this.refs.bbox);
		this.setState({ style: { ...style, ...coords }, focus: true });
	};

	onExit = () => {
		const style = {
			display: 'none',
		};
		this.setState({ style, focus: false });
	};

	render() {
		// prettier-ignore
		const { preloading, loggedIn, user, analytics, showMenu, showProfile, userID, unread_tickets, notifications, unapproved_transactions, unliquidated_loans, lender, withdrawals, cancel_requests, location, wallet_debit_requests } = this.props;
		const { style, focus, hovered } = this.state;
		const _search = location.search.replace('?', '');
		const url = encodeURIComponent(`${location.pathname}${_search !== '' ? `?${_search}` : ''}`);

		return preloading == true ? (
			<div className="loading-page">
				<img src={loader} alt="" />
			</div>
		) : (
			<ScrollToTop>
				<NotificationSystem ref="notifRef" />
				<ReduxBlockUi block="REQUEST_START" unblock="REQUEST_STOP">
					{loggedIn ? (
						<div className="all-wrapper menu-side solid-bg-all">
							<Search style={style} onExit={this.onExit} focus={focus} />
							<div className="top-menu-secondary with-overflow color-scheme-dark">
								<div className="logo-w menu-size">
									<Link className="logo" to="/dashboard">
										<img alt="logo" src={logo} />
										<span>FastCash</span>
									</Link>
								</div>
								<div className="fancy-selector-w">
									<div className="fancy-selector-current">
										<div className="fs-img">
											<img alt="card" src={card} />
										</div>
										<div className="fs-main-info">
											{lender && (
												<div className="fs-name nowrap">{lender.name}</div>
											)}
											{analytics && (
												<div className="fs-sub">
													<span>Balance:</span>
													<strong>{`₦${numeral(analytics.super_payout_balance).format('0,0.00')}`}
													</strong>
												</div>
											)}
										</div>
										{analytics && (
											<div className="fs-extra-info">
												<strong>{numeral(analytics.super_transactions).format('0,0')}</strong>
												<span>Transactions</span>
											</div>
										)}
									</div>
								</div>
								<div className="top-menu-controls" ref="bbox">
									<div className="element-search autosuggest-search-activator mr-5">
										<input
											placeholder="Start typing to search..."
											type="text"
											onFocus={this.onFocus}
										/>
									</div>
									<div className={`messages-notifications os-dropdown-trigger os-dropdown-position-left ${hovered ? 'over':''}`} onMouseEnter={this.enter} onMouseLeave={this.leave}>
										<i className="icon-bell" />
										<div className="new-messages-count">{notifications.length}</div>
										<div className="os-dropdown light message-list">
											{notifications.length > 0 ? (
												<ul>
													{notifications.map((notify, i) => (
														<NotifyItem key={i} notify={notify} />
													))}
												</ul>
											) : (
												<ul>
													<li>
														<div className="no-message-content">No Notifications</div>
													</li>
												</ul>
											)}
										</div>
									</div>
									<Link to="/settings" className="top-icon">
										<i className="os-icon os-icon-ui-46" />
									</Link>
									<Link to="/change-password" className="top-icon">
										<i className="os-icon os-icon-lock" />
									</Link>
									<Link to="/logout" className="top-icon">
										<i className="os-icon os-icon-signs-11" />
									</Link>
								</div>
							</div>
							<div className="layout-w">
								{/* Mobile Menu Layout */}
								<div className="menu-mobile menu-activated-on-click color-scheme-dark">
									<div className="mm-logo-buttons-w">
										<Link className="mm-logo" to="/dashboard">
											<img alt="logo" src={logo} />
											<span>FastCash</span>
										</Link>
										<div className="mm-buttons">
											<div className="content-panel-open">
												<div className="os-icon os-icon-grid-circles" />
											</div>
											<div className="mobile-menu-trigger" onClick={this.toggleMobileMenu}>
												<div className="os-icon os-icon-hamburger-menu-1" />
											</div>
										</div>
									</div>
									<div className="menu-and-user" ref="mmenu">
										<div className="logged-user-w">
											<div className="avatar-w">
												<img alt="profile" src={profile} />
											</div>
											<div className="logged-user-info-w">
												<div className="logged-user-name">{user.name}</div>
												{user && <div className="logged-user-role">{startCase(user.role)}</div>}
											</div>
										</div>
										<ul className="main-menu" />
									</div>
								</div>

								{/* Desktop Menu Layout */}
								{showMenu && (
									<DesktopMenu
										user={user} 
										unread_tickets={unread_tickets}
										analytics={analytics}
										transactions={unapproved_transactions}
										unliq_loans={unliquidated_loans}
										withdrawals={withdrawals}
										cancel_requests={cancel_requests}
										wallet_debit_requests={wallet_debit_requests}
										url={url}
									/>
								)}

								<div className="content-w">
									<Switch>
										<Route path="/dashboard" component={Dashboard} />
										<Route path="/offices" component={Offices} />
										<Route path="/users" component={Users} />
										<Route path="/admin-users" component={AdminUsers} />
										<Route path="/merchants" component={Merchants} exact />
										<Route path="/merchants/:merchantCode" component={ViewMerchant} />
										<Route path="/transactions" component={Transactions} />
										<Route path="/logout" component={Logout} />
										<Route path="/settings" component={Settings} />
										<Route path="/support" component={Support} />
										<Route path="/default-loans" component={DefaultRequests} />
										<Route path="/verify-loans" component={VerifyRequests} />
										<Route path="/approve-loans" component={ApproveRequests} />
										<Route path="/active-loans" component={ActiveLoans} />
										<Route path="/approve-liquidated-loans" component={ApproveLiquidatedLoans} />
										<Route path="/mail" component={Mail} />
										<Route path="/lenders" component={Lenders} />
										<Route path="/loan/loan-history" component={LoanHistory} />
										<Route path="/setup-user" component={CreateUser} />
										<Route path="/loan-request" component={LoanRequest} />
										<Route path="/approve-transactions" component={ApproveTransactions} />
										<Route path="/change-password" component={ChangePassword} />
										<Route path="/multi-funding" component={MultiFunding} />
										<Route path="/edit-customer/:id" component={EditUser} />
										<Route path="/withdrawal-requests" component={WithdrawalRequests} />
										<Route path="/cancel-loan-requests" component={CancelLoanRequests} />										
										<Route path="/wallet-debit-request" component={WalletDebitRequest} />																				
										<Route path="/audit-admin" component={Audit} />
										<Route path="/edit-loan/:id" component={EditLoan} />
										<Route path="/report/:title" component={Report} />
										<Route component={NoMatch} />
									</Switch>
								</div>
							</div>
							<UserProfile showProfile={showProfile} userProfileID={userID} staff={user}/>
						</div>
					) : (
						<Switch>
							<Route exact path="/" component={SignIn} />
							<Route path="/forgot-password" component={ForgotPassword} />
							<Route path="/reset-password" component={ResetPassword} />
							<Route path="/dashboard" component={Dashboard} />
							<Route component={NoMatch} />
						</Switch>
					)}
				</ReduxBlockUi>
			</ScrollToTop>
		);
	}
}

const mapStateToProps = state => {
	return {
		preloading: state.user.preloading,
		loggedIn: state.user.loggedIn,
		notifdata: state.general.notifdata,
		user: state.user.user,
		analytics: state.loan.analytics,
		showMenu: state.user.show_menu,
		showProfile: state.user.show_profile,
		userID: state.user.user_id,
		unread_tickets: state.support.unread,
		unapproved_transactions: state.user.unapproved_transactions,
		unliquidated_loans: state.user.unliquidated_loans,
		withdrawals: state.user.withdrawals,
		cancel_requests: state.user.cancel_requests,
		wallet_debit_requests: state.user.wallet_debit_requests,				
		notifications: state.notifications,
		lender: state.lender.profile,
	};
};

export default withRouter(connect(mapStateToProps, { doNotify }, )(App));
