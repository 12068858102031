import * as types from './types';

export const doNotify = data => {
	return {
		type: types.SET_NOTIFICATION,
		payload: data,
	};
};

export const notifyDone = data => {
	return {
		type: types.DO_NOTIFY_STATUS,
		payload: data,
	};
};

export const loadBanks = payload => {
	return {
		type: types.LOAD_BANKS,
		payload,
	};
};

export const setSlips = data => {
	return {
		type: types.SET_SLIPS,
		payload: data,
	};
};

export const loadRoles = data => {
	return {
		type: types.SET_ROLES,
		payload: data,
	};
};
