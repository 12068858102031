import * as types from './types';
import { settingKey } from '../config/constants';

export const putSettings = settings => {
	return dispatch => {
		localStorage.setItem(settingKey, JSON.stringify(settings));
		dispatch({
			type: types.PUT_SETTINGS,
			payload: settings,
		});
	};
};
