import React from 'react';
import { Link } from 'react-router-dom';

const DesktopMenu = ({ user, unread_tickets, analytics, transactions, unliq_loans, withdrawals, cancel_requests, url, wallet_debit_requests }) => {
	return (
		<div className="desktop-lmenu menu-side-v2-w menu-activated-on-hover flying-menu">
			<ul className="main-menu">
				<li>
					<Link to="/dashboard">
						<div className="icon-w">
							<div className="os-icon os-icon-window-content" />
						</div>
						<span>Dashboard</span>
					</Link>
				</li>
				{user && user.role_name === 'super' && (
					<li>
						<Link to="/lenders">
							<div className="icon-w">
								<div className="os-icon os-icon-coins-4" />
							</div>
							<span>Lenders</span>
						</Link>
					</li>
				)}
				<li>
					<Link to="/transactions">
						<div className="icon-w">
							<div className="os-icon os-icon-coins-4" />
						</div>
						<span>Transactions</span>
					</Link>
				</li>
				{user && user.role_name === 'super-l' && (
					<li>
						<Link to={`/lenders/view/${user.lender_id}`}>
							<div className="icon-w">
								<div className="os-icon os-icon-coins-4" />
							</div>
							<span>View Profile</span>
						</Link>
					</li>
				)}
				{user && user.role === 'super' && (
					<li>
						<Link to="/multi-funding">
							<div className="icon-w">
								<div className="os-icon os-icon-wallet-loaded" />
							</div>
							<span>Multi Funding</span>
							{analytics && <i className={`badge badge-pill badge-${analytics.multi_funding === 0 ? 'success':'danger'}`}>{analytics.multi_funding}</i>}
						</Link>
					</li>
				)}
				<li className="menu-sub-header">
					<span>Loans</span>
				</li>
				<li>
					<Link to="/offices">
						<div className="icon-w">
							<div className="os-icon os-icon-grid-squares" />
						</div>
						<span>Manage Offices</span>
					</Link>
				</li>
				{user && user.role_name === 'super' && (
					<li>
						<Link to="/setup-user">
							<div className="icon-w">
								<div className="os-icon os-icon-user-plus" />
							</div>
							<span>Create User</span>
						</Link>
					</li>
				)}
				{user && user.role_name === 'super' && (
					<li>
						<Link to={`/loan-request?_url=${url}`}>
							<div className="icon-w">
								<div className="os-icon os-icon-wallet-loaded" />
							</div>
							<span>New Loan Request</span>
						</Link>
					</li>
				)}
				{user && (user.role === 'super' || user.role === 'admin') && (
					<li>
						<Link to="/offices/upload-payslips">
							<div className="icon-w">
								<div className="icon-cloud-upload" />
							</div>
							<span>Upload Payslip</span>
						</Link>
					</li>
				)}
				{user && (user.role === 'super' || user.role === 'admin') && (
					<li>
						<Link to="/offices/upload-customers">
							<div className="icon-w">
								<div className="icon-cloud-upload" />
							</div>
							<span>Upload Customer Data</span>
						</Link>
					</li>
				)}
				{user && (user.role === 'super' || user.role === 'admin') && (
					<li>
						<Link to="/offices/upload-schedule">
							<div className="icon-w">
								<div className="icon-cloud-upload" />
							</div>
							<span>Upload Repayment Schedule</span>
						</Link>
					</li>
				)}
				{user && (user.role_name === 'super' || (user.role_name === 'admin-v' && user.lender_id === 1)) && (
					<li className="menu-sub-header">
						<span>Approvals</span>
					</li>
				)}
				{user && (user.role_name === 'super' || (user.role_name === 'admin-v' && user.lender_id === 1)) && (
					<li>
						<Link to="/lenders/approved-loans">
							<div className="icon-w">
								<div className="os-icon os-icon-ui-55" />
							</div>
							<span>Disburse Approved Lender Loans</span>
							{analytics && <i className={`badge badge-pill badge-${analytics.lloans_approved === 0 ? 'success':'danger'}`}>{analytics.lloans_approved}</i>}
						</Link>
					</li>
				)}
				{user && user.role_name === 'super' && (
					<li>
						<Link to="/approve-liquidated-loans">
							<div className="icon-w">
								<div className="os-icon os-icon-wallet-loaded" />
							</div>
							<span>Approve Liquidated Loans</span>
							<i className={`badge badge-pill badge-${unliq_loans === 0 ? 'success':'danger'}`}>{unliq_loans}</i>
						</Link>
					</li>
				)}
				{user && user.role_name === 'super' && (
					<li>
						<Link to="/approve-transactions">
							<div className="icon-w">
								<div className="os-icon os-icon-wallet-loaded" />
							</div>
							<span>Approve Transactions</span>
							<i className={`badge badge-pill badge-${transactions === 0 ? 'success':'danger'}`}>{transactions}</i>
						</Link>
					</li>
				)}
				{user && (user.role_name === 'super' || user.role_name === 'super-l') && (
					<li>
						<Link to="/withdrawal-requests">
							<div className="icon-w">
								<div className="os-icon os-icon-wallet-loaded" />
							</div>
							<span>Withdrawal Requests</span>
							<i className={`badge badge-pill badge-${withdrawals === 0 ? 'success':'danger'}`}>{withdrawals}</i>
						</Link>
					</li>
				)}
				{user && (user.role_name === 'super' || user.role_name === 'super-l') && (
					<li>
						<Link to="/cancel-loan-requests">
							<div className="icon-w">
								<div className="os-icon os-icon-cancel-circle" />
							</div>
							<span>Loan Cancel Requests</span>
							<i className={`badge badge-pill badge-${cancel_requests === 0 ? 'success':'danger'}`}>{cancel_requests}</i>
						</Link>
					</li>
				)}
				{user && (user.role_name === 'super' || user.role_name === 'super-l') && (
					<li>
						<Link to="/wallet-debit-request">
							<div className="icon-w">
								<div className="os-icon os-icon-wallet-loaded" />							
							</div>
							<span>Wallet Debit Requests</span>
							<i className={`badge badge-pill badge-${wallet_debit_requests === 0 ? 'success':'danger'}`}>{wallet_debit_requests}</i>
						</Link>
					</li>
				)}

				<li className="menu-sub-header">
					<span>Support</span>
				</li>
				<li>
					<Link to="/support">
						<div className="icon-w">
							<div className="os-icon os-icon-ui-55" />
						</div>
						<span>Support</span>
						<i className={`badge badge-pill badge-${unread_tickets === 0 ? 'success':'danger'}`}>{unread_tickets}</i>
					</Link>
				</li>
				<li>
					<Link to="/mail/compose">
						<div className="icon-w">
							<div className="os-icon os-icon-email-2-at2" />
						</div>
						<span>Mail</span>
					</Link>
				</li>
				{user && user.role === 'super' && (
					<li>
						<Link to="/loan/loan-history">
							<div className="icon-w">
								<div className="os-icon os-icon-grid" />
							</div>
							<span>Loan History</span>
						</Link>
					</li>
				)}
				{user && user.role === 'super' && (
					<li className="menu-sub-header">
						<span>Users</span>
					</li>
				)}
				{user && user.role === 'super' && (
					<li>
						<Link to="/admin-users">
							<div className="icon-w">
								<div className="os-icon os-icon-hierarchy-structure-2" />
							</div>
							<span>Admin Users</span>
						</Link>
					</li>
				)}
				{user && user.role === 'super' && (
					<li>
						<Link to="/merchants">
							<div className="icon-w">
								<div className="os-icon os-icon-user-male-circle" />
							</div>
							<span>Merchant Manager</span>
						</Link>
					</li>
				)}
				<li className="menu-sub-header">
					<span>Reports</span>
				</li>
				<li>
					<Link to="/report/repayment">
						<div className="icon-w">
							<div className="os-icon os-icon-delivery-box-2" />
						</div>
						<span>Reports</span>
					</Link>
				</li>
			</ul>
		</div>
	);
};

export default DesktopMenu;
