import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';

import { CLOSE_PROFILE } from '../actions/types';
import { httpRequest, baseURL } from '../config/constants';
import Profile from './Profile';

class UserProfile extends Component {
	state = {
		opened: false,
		user: null,
		fetching: false,
		transactions: [],
	};

	componentWillMount() {
		Modal.setAppElement('body');
	}

	closeProfile = () => {
		this.setState({ opened: false });
		this.props.closeProfile({ show_profile: false, user_id: '' });
		document.body.className = 'pages';
	};

	async componentWillUpdate(nextProps, nextState) {
		if (nextProps.showProfile && !nextState.opened) {
			document.body.className = 'pages modal-open';
			this.setState({ opened: true, user: null, fetching: true });
			const { userProfileID } = nextProps;
			if (userProfileID) {
				try {
					const url = `${baseURL}/profile/${userProfileID}`;
					const response = await httpRequest(url, 'GET', true);
					this.setState({
						user: response.user,
						fetching: false,
					});
				} catch (error) {
					console.log(error);
					this.setState({ fetching: false });
				}
			}
		}
	}

	updateUser = (user) => {
		this.setState({
			user: { ...user, debit_request: user.debit_request }
		})				
	}

	render() {
		const { showProfile, staff } = this.props;
		const { user, fetching, transactions } = this.state;
		return (
			showProfile && (
				<>
					<Modal
						isOpen={showProfile}
						onRequestClose={this.closeProfile}
						contentLabel="User Profile"
						shouldCloseOnOverlayClick={false}
						overlayClassName="modal-dialog modal-lg modal-extra-lg"
						className="modal-content"
					>
						<Profile
							user={user}
							closeModal={this.closeProfile}
							fetching={fetching}
							transactions={transactions}
							updateWallet={(data) => 
								this.setState({
									user: { ...user, wallet: data.wallet },
									transactions: [...data.transactions],
								})								
							}
							updateCustomerID={(data) => this.setState({ user: { ...user, customer_id: data.customer_id } }) }
							updateUser={this.updateUser}
							staff={staff}																						
						/>
					</Modal>
					{showProfile ? <div className="modal-backdrop fade show" /> : ''}
				</>
			)
		);
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		closeProfile: () => dispatch({ type: CLOSE_PROFILE }),
	};
};

export default connect(null, mapDispatchToProps)(UserProfile);
