import * as types from './types';
import { httpRequest, baseURL, loanAPI } from '../config/constants';

export const setLoanAnalytics = details => {
	return {
		type: types.SET_LOAN_ANALYTICS,
		payload: details,
	};
};

export const setUSSDSettings = info => {
	return {
		type: types.SET_USSD_SETTINGS,
		payload: info,
	};
};

export const setRemitaID = id => {
	return {
		type: types.SET_REMITA_ID,
		payload: id,
	};
};

export const updateLoanAnalytics = (details, property) => {
	return {
		type: types.UPDATE_LOAN_ANALYTICS,
		payload: details,
		property,
	};
};

export const updateLoan = loan => {
	return {
		type: types.UPDATE_LOAN,
		payload: loan,
	};
};

export const updateLoanDecline = loan => {
	return {
		type: types.DECLINE_LOAN,
		payload: loan,
	};
};

export const verifyLoan = (id, userID) => {
	return dispatch => {
		return httpRequest(`${baseURL}${loanAPI}/verify/${id}`, 'POST', true, {staff: userID});
	};
};

export const approveMoney = data => {
	return dispatch => {
		return httpRequest(`${baseURL}${loanAPI}/approve/${data.id}`, 'POST', true, data);
	};
};

export const bypassDisburse = data => {
	return dispatch => {
		return httpRequest(`${baseURL}${loanAPI}/bypass/${data.id}`, 'POST', true, data);
	};
};

export const sendMail = data => {
	return dispatch => {
		return httpRequest(`${baseURL}${loanAPI}/email/${data.id}`, 'POST', true, data);
	};
};

export const verifyMoneyPaid = data => {
	return dispatch => {
		return httpRequest(`${baseURL}${loanAPI}/verify-payment/${data.id}`, 'POST', true, data);
	};
};

export const disburseMoney = data => {
	return dispatch => {
		return httpRequest(`${baseURL}${loanAPI}/disburse-loan/${data.id}`, 'POST', true, data);
	};
};

export const declineLoan = data => {
	return dispatch => {
		return httpRequest(`${baseURL}${loanAPI}/${data.id}`, 'DELETE', true, data);
	};
};

// loan request actions
export const setTenure = tenure => {
	return {
		type: types.SET_TENURE,
		payload: tenure,
	};
};

export const setEarnings = details => {
	return {
		type: types.SET_LOAN_EARNINGS,
		payload: details,
	};
};

export const doRefresh = status => {
	return {
		type: types.REFRESH_LOAN,
		payload: status,
	};
};

export const setNetEarning = amount => {
	return {
		type: types.SET_NET_EARNING,
		payload: amount,
	};
};

export const setMonthlyRepayment = amount => {
	return {
		type: types.SET_MONTHLY_REPAYMENT,
		payload: amount,
	};
};

export const setEligibleAmount = amount => {
	return {
		type: types.SET_ELIGIBLE_AMT,
		payload: amount,
	};
};

export const setMyAmount = amount => {
	return {
		type: types.SET_DESIRED_AMOUNT,
		payload: amount,
	};
};

export const setMyMonthlyDeduction = amount => {
	return {
		type: types.SET_DESIRED_MONTHLY_REPAYMENT,
		payload: amount,
	};
};

export const setDisburseLoan = amount => {
	return {
		type: types.SET_DISBURSE_AMOUNT,
		payload: amount,
	};
};

export const setMyMonthlyPrincipal = amount => {
	return {
		type: types.SET_MONTHLY_PRINCIPAL,
		payload: amount,
	};
};

export const setMyMonthlyInterest = amount => {
	return {
		type: types.SET_MONTHLY_INTEREST,
		payload: amount,
	};
};

export const calculateNewLoan = (amount, tenure, interest_rate, _, override) => {
	return dispatch => {
		dispatch(setMyAmount(amount));

		if(!override) {
			const repayment = (amount * (1 / tenure + interest_rate / 100)).toFixed(2);

			dispatch(setMyMonthlyDeduction(repayment));
	
			const monthly_principal = (amount / tenure).toFixed(2);
			const monthly_interest = (repayment - monthly_principal).toFixed(2);
	
			dispatch({ type: types.SET_MONTHLY_PRINCIPAL, payload: monthly_principal });
			dispatch({ type: types.SET_MONTHLY_INTEREST, payload: monthly_interest });
	
			let monthly_comm = 0;
			dispatch({ type: types.SET_MONTHLY_COMMISSION, payload: monthly_comm });
		}
	};
};

export const calculateLoan = (net_earning, tenure, interest_rate, percent) => {
	return dispatch => {
		const monthly_repayment = (net_earning * percent).toFixed(2);
		const eligible_amt = (monthly_repayment / (1 / tenure + interest_rate / 100)).toFixed(2);

		dispatch(setNetEarning(net_earning));
		dispatch(setMonthlyRepayment(monthly_repayment));
		dispatch(setEligibleAmount(eligible_amt));
	};
};

export const calculateSMELoan = eligible_amt => {
	return dispatch => {
		dispatch(setNetEarning(eligible_amt));
		dispatch(setMonthlyRepayment(0));
		dispatch(setEligibleAmount(eligible_amt));
	};
};

export const resetLoanData = () => {
	return {
		type: types.RESET_LOAN_DATA,
	};
};
