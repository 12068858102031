import React from 'react';
import moment from 'moment';

import { currency } from '../config/constants';

const RemitaData = ({ data, closeModal }) => {
	const salaryPaymentDetails = data && data.salaryPaymentDetails ? data.salaryPaymentDetails : [];

	return (
		<>
			<div className="modal-header faded smaller">
				<div className="modal-title">
					<span>Remita Data</span>
				</div>
				<button aria-label="Close" className="close" onClick={closeModal} type="button" style={{opacity:1}}><span aria-hidden="true"> ×</span></button>
			</div>
			<div className="modal-body">
				<div className="content-i">
					<div className="content-box">
						{data && (
							<div className="element-wrapper">
								<h6 className="element-header">Loan History</h6>
								<div className="element-box">
									<div className="row">
										<div className="col-sm-7">
											<div className="table-responsive" style={{overflowX: 'scroll'}}>
												<table className="table table-lightborder">
													<thead>
														<tr>
															<td>Customer id</td>
															<td>Customer phone</td>
															<td>Customer name</td>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td className="text-left">{data.customerId}</td>
															<td className="text-left">{data.customerPhone || ''}</td>
															<td className="text-left">{data.customerName}</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
										<div className="col-sm-12">
											<div className="table-responsive" style={{overflowX: 'scroll'}}>
												<table className="table table-lightborder">
													<tbody>
														<tr>
															<th>Loan Provider</th>
															<th>Amount</th>
															<th>Outstanding Amt.</th>
															<th>Repayment Amt.</th>
															<th>Status</th>
															<th>Date</th>
														</tr>
														{data.loanHistoryDetails && data.loanHistoryDetails.map((info, i) => {
															return (
																<tr key={i}>
																	<td>{info.loanProvider}</td>
																	<td>{currency(info.loanAmount)}</td>
																	<td>{currency(info.outstandingAmount)}</td>
																	<td>{currency(info.repaymentAmount)}</td>
																	<td>{info.status === 'STOP' ? 'ENDED' : info.status}</td>
																	<td>{info.loanDisbursementDate.split(' ')[0]}</td>
																</tr>
															);
														})}
														{!data.loanHistoryDetails || (data.loanHistoryDetails && data.loanHistoryDetails.length === 0 && <tr><td colSpan="6">No loan found</td></tr>)}
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
								<h6 className="element-header mt-5">Net Earnings</h6>
								<div className="element-box">
									<div className="table-responsive" style={{overflowX: 'scroll'}}>
										<table className="table table-lightborder">
											<tbody>
												<tr>
													<th>Date</th>
													<th>Net Earning</th>
												</tr>
												{salaryPaymentDetails.map((info, i) => {
													const date = info.paymentDate.split('+');
													return (
														<tr key={i}>
															<td>{moment(date[0], "DD-MM-YYYY HH:mm:ss").format('MMM-YYYY')}</td>
															<td>{currency(info.amount)}</td>
														</tr>
													);
												})}
												{salaryPaymentDetails.length === 0 && (
													<tr>
														<td colSpan="2" className="text-center">No loan found</td>
													</tr>
												)}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default RemitaData;