/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Pagination from 'antd/lib/pagination';
import startCase from 'lodash.startcase';

import loader from '../assets/img/loader.gif';
import {
	baseURL,
	httpRequest,
	walletAPI,
	currency,
	formatDate,
} from '../config/constants';
import { doNotify } from '../actions/general';

const itemRender = (current, type, originalElement) => {
	if (type === 'prev') {
		return <a>Previous</a>;
	}
	if (type === 'next') {
		return <a>Next</a>;
	}
	return originalElement;
};
const pageSize = 10;

const WalletHistory = ({ closeModal, user, wallet }) => {
	const [fetching, setFetching] = useState(false);
	const [loaded, setLoaded] = useState(false);
	const [items, setItems] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPage, setTotalPage] = useState(0);

	const dispatch = useDispatch();

	useEffect(() => {
		const fetch = async () => {
			await fetchHistory();
		};

		if (!loaded) {
			fetch();
			setLoaded(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loaded]);

	const fetchHistory = async (page) => {
		try {
			setFetching(true);
			const url = `${baseURL}${walletAPI}?user_id=${user.id}&page=${page || 1}`;
			const rs = await httpRequest(url, 'GET', true);
			setItems(rs.result.data);
			setTotalPage(rs.result.count);
			setFetching(false);
		} catch (error) {
			setFetching(false);
			const message = error.message || 'could not fetch wallet history';
			notify('', message, 'error');
		}
	};

	const notify = (title, message, type) => {
		dispatch(doNotify({ message, level: type, title }));
	};

	const onChange = (pageNumber) => {
		setCurrentPage(pageNumber);
		fetchHistory(pageNumber);
	};

	return (
		<div>
			<div className="modal-header faded smaller">
				<div className="modal-title">
					<span>{`Wallet History - Balance: ${currency(wallet)}`}</span>
				</div>
				<button
					aria-label="Close"
					className="close"
					onClick={closeModal}
					type="button"
				>
					<span aria-hidden="true"> ×</span>
				</button>
			</div>
			<div className="element-box">
				{fetching && (
					<div className="fetching-load">
						<img alt="fetching" src={loader} />
					</div>
				)}
				{!fetching && (
					<div className="table-responsive">
						<table className="table table-striped table-lightfont">
							<thead>
								<tr>
									<th>Date</th>
									<th>Loan ID</th>
									<th>Amount</th>
									<th>Description</th>
									<th>Type</th>
									<th>Balance</th>
								</tr>
							</thead>
							<tbody>
								{items.map((wallet, i) => {
									return (
										<tr key={i}>
											<td>
												{wallet.transaction && wallet.transaction.repayment_date ? formatDate(wallet.transaction.repayment_date, 'YYYY-MM-DD HH:mm:ss'): formatDate(wallet.created_at, 'YYYY-MM-DD HH:mm:ss')}
											</td>
											<td>{wallet.loan_id || '-'}</td>
											<td>{currency(wallet.amount)}</td>
											<td>
												{wallet.transaction
													? (wallet.transaction.channel === 'wallet-withdrawal'
														? 'Withdrawal by customer'
														: wallet.transaction.description)
													: startCase(wallet.category)}
											</td>
											<td>{startCase(wallet.type)}</td>
											<td>{currency(wallet.single.new_amount || 0)}</td>
										</tr>
									);
								})}
								{items.length === 0 && (
									<tr>
										<td colSpan="6" className="text-center">
											No wallet items found!
										</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
				)}
				<div className="pagination pagination-center mt-4">
					<Pagination
						current={currentPage}
						pageSize={pageSize}
						total={totalPage}
						showTotal={(total) => `Total ${total} items`}
						itemRender={itemRender}
						onChange={onChange}
					/>
				</div>
			</div>
		</div>
	);
};

export default WalletHistory;
