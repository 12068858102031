/* eslint eqeqeq: 0 */
import * as types from '../actions/types';

const INITIAL_STATE = {
	list: [],
	mail: null,
};

const mail = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.LOAD_MAIL:
			return { ...state, list: [...action.payload] };
		case types.ADD_MAIL:
			return { ...state, list: [...action.payload, ...state.list] };
		case types.SELECT_MAIL:
			return { ...state, mail: action.payload };
		case types.UPDATE_MAIL:
			return { ...state, list: [] };
		case types.DELETE_MAIL:
			return { ...state, list: [...state.list.filter(m => m.id != action.payload)] };
		case types.SIGN_OUT:
			return { ...state, ...INITIAL_STATE };
		default:
			return state;
	}
};

export default mail;
