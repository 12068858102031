import React, { useState, useCallback, useEffect } from 'react';

import TransactionItem from './TransactionItem';
import { baseURL, httpRequest } from '../config/constants';
import loader from '../assets/img/loader.gif';

const LoanTransactions = ({ user, transactions, current_loan, updateWallet }) => {
	const [fetching, setFetching] = useState(true);

	const fetchTransactions = useCallback(async (id) => {
		try {
			const url = `${baseURL}/user-transactions/${id}`;
			const rs = await httpRequest(url, 'GET', true);
			updateWallet(rs);
		} catch (error) {
			console.log(error);
			setFetching(false);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (fetching) {
			fetchTransactions(user.id);
			setFetching(false);
		}
	}, [fetchTransactions, fetching, user.id]);

	return fetching ? (
		<div className="fetching-load">
			<img alt="fetching" src={loader} />
		</div>
	) : (
		<div className="row">
			<div className="col-sm-12">
				<h6 className="element-header">Loan Transaction History</h6>
				<div className="table-responsive">
					<table className="table table-striped">
						<thead>
							<tr>
								<th></th>
								<th>Loan ID</th>
								<th>Lender</th>
								<th>Description</th>
							</tr>
						</thead>
						<tbody>
							{transactions.map((transaction, i) => {
								return (
									transaction.loan && (
										<TransactionItem
											key={i}
											current_loan={current_loan}
											item={transaction}
										/>
									)
								);
							})}
							{transactions.length === 0 && (
								<tr>
									<td colSpan="4">No transactions</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default LoanTransactions;
