import React from 'react';
import { Link } from 'react-router-dom';

const NotifyItem = ({ notify }) => {
	return (
		notify.loan && (
			<li>
				<Link to={`/offices/view/${notify.loan.office_id}?search=${notify.loan.user.phone}&p=1&office=${notify.loan.office.name}`}>
					<div className="message-content">
						<h6 className="message-from">{notify.loan.user.name}</h6>
						<h6 className="message-title">New Loan Request</h6>
					</div>
				</Link>
			</li>
		)
	);
};

export default NotifyItem;
